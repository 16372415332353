
class officeTab {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
    $('.js-office-tab01').on('click',function(e) {
        e.preventDefault();
        $(this).addClass('tab-active');
        $('.js-office-tab02').removeClass('tab-active');
        $('.tab02').hide();
        $('.tab01').show();
    })
    $('.js-office-tab02').on('click',function(e) {
        e.preventDefault();
        $(this).addClass('tab-active');
        $('.js-office-tab01').removeClass('tab-active');
        $('.tab01').hide();
        $('.tab02').show();
    })
  }
}

export default officeTab;