/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
    $('.js_link').on('click',function(e) {
      e.preventDefault();
      var url = location.href;
      alert('リンクをコピーしました。')
      navigator.clipboard.writeText(url);
    })

    let urlParam = document.URL;
        if(urlParam.match(/#building/)) {
          $('.js-office-tab02').addClass('tab-active');
          $('.js-office-tab01').removeClass('tab-active');
          $('.tab01').hide();
          $('.tab02').show();
        }
  }
}

export default Common;
