/**
 * コモンクラス
 * @constructor
 */
 class Conference {
    constructor() {
  
    }
    /**
     * コモン
     */
    init() {

        function confSlide(btn,list) {
            $(btn).on('click',function(e) {
                e.preventDefault();
                $(this).toggleClass('menuClose');
                $(this).toggleClass('menuOpen');
                $(list).slideToggle();
            })
        }

        confSlide('.js-conf01','.js-confList01')
        confSlide('.js-conf02','.js-confList02')
        confSlide('.js-conf03','.js-confList03')


    }
  }
  
  export default Conference;