/**
 * コモンクラス
 * @constructor
 */
 class Header {
    constructor() {

    }
    /**
     * コモン
     */
    init() {

        // SP版メニュー開閉
        function openMenu(btn,close,open,elem) {
            $(btn).on('click',function(e) {
                e.preventDefault();
                // e.stopPropagation();
                $(btn).toggleClass(close)
                $(btn).toggleClass(open)
                $(elem).slideToggle();
            })
        }
        openMenu('.js-ham','ham_close','ham_open','.m-ham')
        openMenu('.js-acc01','accOpen','accClose','.js-ham-acc01')
        openMenu('.js-acc02','accOpen','accClose','.js-ham-acc02')
        openMenu('.js-acc03','accOpen','accClose','.js-ham-acc03')
        openMenu('.js-acc04','accOpen','accClose','.js-ham-acc04')
        $('.m-ham__acc a').click(function(event){
            event.stopPropagation();
          });

        // PC版メニュー
        $('.js-menu').on('mouseover',function() {
            $(this).addClass('active');

            if(!$(this).hasClass('js-head-open00')) {
                $('.js-head-open00').removeClass('active')
            }
            if($(this).hasClass('js-head-open00')) {
                $('.js-top-menu00').slideDown();
            }else if(!$(this).hasClass('js-head-open00')) {
                $('.js-top-menu00').slideUp();
            }

            if(!$(this).hasClass('js-head-open01')) {
                $('.js-head-open01').removeClass('active')
            }
            if($(this).hasClass('js-head-open01')) {
                $('.js-top-menu01').slideDown();
            }else if(!$(this).hasClass('js-head-open01')) {
                $('.js-top-menu01').slideUp();
            }

            if(!$(this).hasClass('js-head-open02')) {
                $('.js-head-open02').removeClass('active')
            }
            if($(this).hasClass('js-head-open02')) {
                $('.js-top-menu02').slideDown();
            }else if(!$(this).hasClass('js-head-open02')) {
                $('.js-top-menu02').slideUp();
            }

            if(!$(this).hasClass('js-head-open03')) {
                $('.js-head-open03').removeClass('active')
            }
            if($(this).hasClass('js-head-open03')) {
                $('.js-top-menu03').slideDown();
            }else if(!$(this).hasClass('js-head-open03')) {
                $('.js-top-menu03').slideUp();
            }
        })
        $('.js-menu').on('mouseout',function() {
            if(!$(this).hasClass('js-head-open00') ) {
                $(this).removeClass('active')
            }
            if(!$(this).hasClass('js-head-open00') ) {
                $('.js-top-menu00').slideUp();
            }

            if(!$(this).hasClass('js-head-open01') ) {
                $(this).removeClass('active')
            }
            if(!$(this).hasClass('js-head-open01') ) {
                $('.js-top-menu01').slideUp();
            }

            if(!$(this).hasClass('js-head-open02')) {
                $(this).removeClass('active')
            }
            if(!$(this).hasClass('js-head-open02')) {
                $('.js-top-menu02').slideUp();
            }

            if(!$(this).hasClass('js-head-open03')) {
                $(this).removeClass('active')
            }
            if(!$(this).hasClass('js-head-open03')) {
                $('.js-top-menu03').slideUp();
            }
        })

        $('.l-main').on('mouseover',function() {
            $('.js-top-menu02').slideUp();
            $('.js-top-menu01').slideUp();
            $('.js-top-menu00').slideUp();
            $('.js-top-menu03').slideUp();
        })

        let urlParam = document.URL;
        if(urlParam.match(/nomurabldg/)) {
            $('.l-header__menuItem--01').addClass('active02')
        }
        if(urlParam.match(/workers/)) {
            $('.l-header__menuItem--02').addClass('active02')
        }
  
    }
  
  }
  
  export default Header;
  