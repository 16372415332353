/**
 * サンプルクラス
 * @constructor
 */
 class slidePlan {
    constructor() {
  
    }
    /**
     * サンプル
     */
    init() {
        let num = 0;
        const wrap = $('.p-plan__slideItemWrap');
        $('.js-plan-prev').on('click',function(e) {
            e.preventDefault();

            if(num === 1) {
                wrap.removeClass('page01');
                wrap.addClass('page00');
                $('.js-plan-prev').addClass('disable');
                $('.plan_btn--01').addClass('plan-show');
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--01').addClass('plan-show');
                num--;
            }else if(num === 2) {
                wrap.removeClass('page02')
                wrap.addClass('page01')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--02').addClass('plan-show');
                num--;
            }else if(num === 3) {
                wrap.removeClass('page03')
                wrap.addClass('page02')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--03').addClass('plan-show');
                num--;
            }else if(num === 4) {
                wrap.removeClass('page04')
                wrap.addClass('page03')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--04').addClass('plan-show');
                num--;
            }else if(num === 5) {
                wrap.removeClass('page05')
                wrap.addClass('page04')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--05').addClass('plan-show');
                num--;
            }else if(num === 6) {
                wrap.removeClass('page06')
                wrap.addClass('page05')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--06').addClass('plan-show');
                num--;
            }else if(num === 7) {
                wrap.removeClass('page07')
                wrap.addClass('page06')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--07').addClass('plan-show');
                num--;
            }else if(num === 8) {
                wrap.removeClass('page08')
                wrap.addClass('page07')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--08').addClass('plan-show');
                num--;
            }
            console.log(num)
        })
        $('.js-plan-next').on('click',function(e) {
            e.preventDefault();
            $('.js-plan-prev').removeClass('disable')
            if(num === 0) {
                wrap.removeClass('page00')
                wrap.addClass('page01')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--02').addClass('plan-show');
                num++;
            }else if(num === 1) {
                wrap.removeClass('page01')
                wrap.addClass('page02')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--03').addClass('plan-show');
                num++;
            }else if(num === 2) {
                wrap.removeClass('page02')
                wrap.addClass('page03')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--04').addClass('plan-show');
                num++;
            }else if(num === 3) {
                wrap.removeClass('page03')
                wrap.addClass('page04')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--05').addClass('plan-show');
                num++;
            }else if(num === 4) {
                wrap.removeClass('page04')
                wrap.addClass('page05')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--06').addClass('plan-show');
                num++;
            }else if(num === 5) {
                wrap.removeClass('page05')
                wrap.addClass('page06')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--07').addClass('plan-show');
                num++;
            }else if(num === 6) {
                wrap.removeClass('page06')
                wrap.addClass('page07')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--08').addClass('plan-show');
                num++;
            }else if(num === 7) {
                wrap.removeClass('page07')
                wrap.addClass('page08')
                $('.plan_btn').removeClass('plan-show')
                $('.plan_btn--09').addClass('plan-show');
                num++;
                $('.js-plan-next').addClass('disable')
            }
            console.log(num)
        })

        $('.plan_btn').on('click',function(e) {
            e.preventDefault();
            $('.plan_btn').removeClass('plan-show');
            $(this).addClass('plan-show')
            if($(this).hasClass('plan_btn--01')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page02')
                .removeClass('page03')
                .removeClass('page04')
                .removeClass('page05')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page00');
                num= 0;
                $('.js-plan-prev').addClass('disable')
            }else if($(this).hasClass('plan_btn--02')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page00')
                .removeClass('page02')
                .removeClass('page03')
                .removeClass('page04')
                .removeClass('page05')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page01');
                num= 1;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--03')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page03')
                .removeClass('page04')
                .removeClass('page05')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page02');
                num= 2;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--04')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page02')
                .removeClass('page04')
                .removeClass('page05')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page03');
                num= 3;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--05')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page03')
                .removeClass('page02')
                .removeClass('page05')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page04');
                num= 4;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--06')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page03')
                .removeClass('page02')
                .removeClass('page04')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page05');
                num= 5;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--07')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page03')
                .removeClass('page02')
                .removeClass('page05')
                .removeClass('page04')
                .removeClass('page07')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page06');
                num= 6;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--08')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page03')
                .removeClass('page02')
                .removeClass('page05')
                .removeClass('page04')
                .removeClass('page06')
                .removeClass('page08')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page07');
                num= 7;
                $('.js-plan-prev').removeClass('disable')
                $('.js-plan-next').removeClass('disable')
            }else if($(this).hasClass('plan_btn--09')) {
                $('.p-plan__slideItemWrap')
                .removeClass('page01')
                .removeClass('page00')
                .removeClass('page03')
                .removeClass('page02')
                .removeClass('page05')
                .removeClass('page04')
                .removeClass('page06')
                .removeClass('page07')
                .removeClass('page09')
                $('.p-plan__slideItemWrap').addClass('page08');
                num= 8;
                $('.js-plan-next').addClass('disable')
            }
        })

        function openSlideAcc(btn,cont) {
            $(btn).on('click',function(e) {
                e.preventDefault();
                $(this).toggleClass('plan-open')
                $(this).toggleClass('plan-close')
                $(cont).slideToggle();
            })
        }


        openSlideAcc('.js-acc-plan01','.js-cont01')
        openSlideAcc('.js-acc-plan02','.js-cont02')
        openSlideAcc('.js-acc-plan03','.js-cont03')
        openSlideAcc('.js-acc-plan04','.js-cont04')
    }
  };
  
  export default slidePlan;
  