import Swiper from "swiper";
/**
 * サンプルクラス
 * @constructor
 */
 class Slide {
    constructor() {
  
    }

    init() {


      let mySwiperPlan = new Swiper(".plan_swiper", {
        navigation: {
          nextEl: ".plan_next",
          prevEl: ".plan_prev"
        }
      });

      // TOPページ
        let mySwiperTop = new Swiper(".swiper-containertop", {
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: true,
            loop: true,
            effect: 'fade',
            speed: 400,
            pagination: {
              el: '.m-slide__pagenationTop',
              type: 'bullets',
              clickable: true,
          },
            autoplay: {
              delay: 3500,
              disableOnInteraction:false,
            },
            breakpoints: {
              750: {
                centeredSlides: false,
                slidesPerView: 1,
                spaceBetween: 0
              }
            },
          });
         

          var windowWidth = $(window).width();
          var windowSm = 750;
          if (windowWidth <= windowSm) {
            // SPのみスライダー


            let mySwiperConf = new Swiper(".p-conference__imgWrap", {
              slidesPerView: 'auto',
              centeredSlides : true,
              loopAdditionalSlides: 1,
              breakpoints: {
                750: {
                  loop:true,
                }
              }
            })

              let mySwiperWorkers = new Swiper(".m-slide__cont01", {
                slidesPerView: 'auto',
                centeredSlides : true,
                loop:true,
                loopAdditionalSlides: 1,
                breakpoints: {
                  750: {
                  }
                }
              })

            let mySwiperWorkers02 = new Swiper(".m-slide__cont02", {
              slidesPerView: 'auto',
              centeredSlides : true,
              breakpoints: {
                750: {
                  pagination: {
                    el: '.m-slide__pagenation01',
                    type: 'bullets',
                    clickable: true,
                },
                }
              }
            })
          }else {
            if($('.m-slide__item01').length > 3) {
              if($('.m-slide__item01').length === 4) {
                let mySwiperWorkers = new Swiper(".m-slide__cont01", {
                  slidesPerView: 3,
                  // centeredSlides : true,
                  breakpoints: {
                    750: {
                    }
                  }
                })
              }else {
                let mySwiperWorkers = new Swiper(".m-slide__cont01", {
                  slidesPerView: 3,
                  // centeredSlides : true,
                  breakpoints: {
                    750: {
                    }
                  }
                })
              }
              if($('.m-slide__item01').length >= 4) {
                if($('.m-slide__item01').length % 2 === 0 ) {
                  $('.m-slide__cont01').addClass('even')
                }
              }
            }
          }
         
    }
};

export default Slide;