/**
 * コモンクラス
 * @constructor
 */
 class Pagenavi {
    constructor() {
  
    }
    /**
     * コモン
     */
    init() {
        // $(function(){
        //     if(!($('.first').length)){
        //         console.log('ない')
        //     }else {
        //         console.log('aru')
        //     }
        // });

        if(!($('.first').length)){
            $('.wp-pagenavi').addClass('first-space')
        }else if (!($('.last').length)) {
            $('.wp-pagenavi').addClass('last-space')
        }

    }
}
export default Pagenavi;