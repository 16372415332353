/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
// import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';

/**
 * import modules
 */
import Sample from './modules/sample';
import Common from './modules/common';
import Header from './modules/header';
import Slide from './modules/slide';
import Pagenavi from './modules/pagenavi';
import SlidePlan from './modules/slidePlan';
import OfficeTab from './modules/officeTab';
import Conference from './modules/Conference';
import Modal from './modules/Modal';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    const sample = new Sample();
    sample.init();

    const common = new Common();
    common.init();

    const header = new Header();
    header.init();

    const slide = new Slide();
    slide.init();

    const pagenavi = new Pagenavi();
    pagenavi.init();

    const slidePlan = new SlidePlan();
    slidePlan.init();
    
    const officeTab = new OfficeTab();
    officeTab.init();

    const conference = new Conference();
    conference.init();

    const modal = new Modal();
    modal.init();
  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {


    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();
  }



  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
