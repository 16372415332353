/**
 * コモンクラス
 * @constructor
 */
 class Modal {
    constructor() {
  
    }
    /**
     * コモン
     */
    init() {

        function modalOpen(btn,img) {
            $(btn).on('click',function(e) {
                e.preventDefault();
                $(img).removeClass('modal_close')
                $(img).addClass('modal_imgOpen')
            })
        }
        modalOpen('.js-plan01','.m-modal01')
        modalOpen('.js-plan02','.m-modal02')
        modalOpen('.js-plan03','.m-modal03')
        modalOpen('.js-plan04','.m-modal04')
        modalOpen('.js-plan05','.m-modal05')
        modalOpen('.js-plan06','.m-modal06')
        modalOpen('.js-plan07','.m-modal07')
        modalOpen('.js-plan08','.m-modal08')
        modalOpen('.js-plan09','.m-modal09')

        $('.js-plan-close').on('click',function(e) {
            e.preventDefault();
            $('.m-modal').removeClass('modal_imgOpen')
            $('.m-modal').addClass('modal_close')
        })
        $('.m-modal').on('click',function(e) {
            e.preventDefault();
            $('.m-modal').removeClass('modal_imgOpen')
            $('.m-modal').addClass('modal_close')
        })
    }
  }
  
  export default Modal;